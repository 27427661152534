<template>
  <div class="page project">
    <SectionSelector
      v-for="(section, key) in sections"
      ref="sections"
      :enabled="section.enabled"
      :hash="section.hashAnchor"
      :variant="section.variant"
      :data="section.data"
      :extra="section.extra"
      :multi-project="section.multi_project"
      :section="section.type"
      :order-no="section.order_no"
      :key="key"
      v-scroll="handleScroll"
    />
  </div>
</template>

<script>
import SectionSelector from "./SectionSelector";
import buildingStore from "../../components/organisms/project/building/store";
import config from "../../config";

export default {
  name: "PageSpaceEmbed",
  metaInfo() {
    return {
      title: this.meta.baseTitle,
      titleTemplate: `%s - ${this.meta.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.meta.description,
        },
      ],
    };
  },
  components: {
    SectionSelector,
  },
  computed: {
    buildingStore() {
      return buildingStore;
    },
    meta() {
      return this.$route.meta.page.meta;
    },
    sectionsData() {
      return [
        this.$route.meta.page.sections.find((s) => s.type === "Building"),
      ];
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    spaces() {
      return this.$store.state.project.project.spaces;
    },
    baseConstants() {
      return this.$store.getters.constants.base;
    },
    buildingRef() {
      const found = this.$refs.sections.find((s) => s.$refs.buildingSection);
      if (!found) return null;
      return found.$refs.buildingSection.$refs.building;
    },
    spaceId() {
      return (
        this.$route.params.spaceId ||
        this.$store.getters.getFirstAvailableSpaceIdWhenBuildingModelIsNot
      );
    },
  },
  data() {
    return {
      sections: [],
    };
  },
  created() {
    this.sections = this.sectionsData.sort((a, b) => {
      if (a.order_no < b.order_no) {
        return -1;
      }
      if (a.order_no > b.order_no) {
        return 1;
      }
      return 0;
    });
    const projectConstants = this.$store.getters.constants.project;
    this.$store.dispatch(
      projectConstants.withNamespace(
        projectConstants.action.LOAD_LANDLORD_DETAILS
      )
    );
  },
  beforeMount() {
    const requestOffer = this.$store.getters.constants.requestOffer;
    this.$store.dispatch(
      requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
      null
    );
    ``;
  },
  watch: {
    spaces: {
      deep: true,
      handler: function (value, old) {
        this.removePageLoader();
        this.buildingRef.initializeFloor3D(parseInt(this.spaceId));
      },
    },
  },
  mounted() {
    window.addEventListener("keydown", this.disableArrowScroll, false);
    const { params } = this.$route;
    this.buildingRef.initializeFloor3D(parseInt(this.spaceId));

    const self = this;
    document
      .querySelectorAll('a.navigation-link[href*="#"]')
      .forEach((anchor) => {
        anchor.addEventListener("click", self.smoothScroll);
      });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.disableArrowScroll);
    const self = this;
    document
      .querySelectorAll('a.navigation-link[href*="#"]')
      .forEach((anchor) => {
        anchor.removeEventListener("click", self.smoothScroll);
      });
  },
  methods: {
    disableArrowScroll(e) {
      if (
        ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1
      ) {
        e.preventDefault();
      }
    },
    handleScroll(event, element) {
      const scrollY = window.scrollY + 100;
      document
        .querySelectorAll('a.navigation-link[href*="#"]')
        .forEach((link) => {
          link.classList.remove("active");
          // const section = document.getElementById(`${link.getAttribute('href').substring(1)}`).parentElement;
          const section = document.getElementById(`${link.href.split("#")[1]}`)
            .parentElement;
          if (
            section.offsetTop <= scrollY &&
            section.offsetTop + section.offsetHeight > scrollY
          ) {
            link.classList.add("active");
          }
        });
    },
    smoothScroll(e) {
      e.preventDefault();
      document
        .querySelector(`#${e.target.getAttribute("href").split("#")[1]}`)
        .scrollIntoView({
          behavior: "smooth",
        });

      const links = this.$el.querySelectorAll('a.navigation-link[href*="#"]');
      links.forEach((link) => {
        const activeLink = e.path[0];
        link.classList.remove("active");
        if (link === activeLink && !activeLink.classList.contains("active")) {
          link.classList.add("active");
        }
      });
    },
    removePageLoader() {
      if (this.$store.state.base.documentIsReady) {
        this.$store.dispatch(
          this.baseConstants.withNamespace(
            this.baseConstants.action.CHANGE_PAGE_LOADING_STATE
          ),
          false
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/buttons";

.project {
  padding: 0 0.625rem;
  @media only screen and (max-width: 767px) {
    padding: 0 10px;
  }

  .section {
    position: relative;
    &:last-child {
      margin: 0;
    }
    .navigation-target {
      position: absolute;
      top: -4.5rem;
      left: 0;
    }

    .top-title {
      display: flex;
      flex-direction: column;
      flex: 100%;
    }
  }
}
</style>
